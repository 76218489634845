/*//=require components/example.js*/

//=require components/**.*


$(document).ready(function(){

    var phonemaskOk = 0;

    $(".MaskedPhone").inputmask("8 (999) 999-99-99",{
        "oncomplete": function(){ phonemaskOk = 1; },
        "onincomplete": function(){ phonemaskOk = 0; },
        "oncleared": function(){ phonemaskOk = 0; }
    });

    $('input').keypress(function(e){
        if(e.keyCode==13)
        {
            e.preventDefault();
            var qid = $(this).parents('form').attr('alt');
            var qreachgoal = $(this).parents('form').attr('data-reachgoal');
            formcheck(qid, qreachgoal);
        }
    });

    $(".FancyBtn").fancybox({
        maxWidth : 700,
        padding: 0
    });

    $(".FancyMedia").fancybox({
        padding: 0
    });

    $(".ProcessingPersonalDataText-Link, .ImPolitic").fancybox({
        maxWidth : 700
    });

    $('.FeedbackSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: '<div class="FeedbackSlider-Arrow FeedbackSlider-Arrow_prev">\n' +
        '                        <img src="/img/FeedbackSlider-Arrow.png" alt="" class="FeedbackSlider-Arrow-Img">\n' +
        '                    </div>',
        nextArrow: '<div class="FeedbackSlider-Arrow FeedbackSlider-Arrow_next">\n' +
        '                        <img src="/img/FeedbackSlider-Arrow.png" alt="" class="FeedbackSlider-Arrow-Img">\n' +
        '                    </div>'
    });

    $('.FormSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        prevArrow: '',
        nextArrow: '<button type="button" class="FormSlider-Arrow FormSlider-Next Btn">' +
        'Продолжить <img src="/img/SliderArrow.png" alt="">' +
        '</button>'
    });

    var myDefMenu = new DefMenu({
        btn : ".DefmenuBtn"
        //menu : ".mydefmenu",
        //build : true,
        //links: "auto"
    });

    $('.Accordion-Title').on('click', function () {
        $(this).parent().toggleClass('Accordion-Item_open');
    });

    function animatedAnchorScroll(self) {
        var target = $(self).attr('href');
        $('html, body').animate({ scrollTop: $(target).offset().top-80}, 500);
    }
    $('a[href^="#"]').click(function () {
        animatedAnchorScroll(this);
        myDefMenu.close();
        return false;
    });

    validateForm({
        formId: 'form-intro'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-offer'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-contact'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });

    var grid = document.querySelector('.PortfolioGrid');
    var iso = new Isotope( grid, {
        // options...
        itemSelector: '.PortfolioGrid-Item',
        percentPosition: true,
        masonry: {
            // use element for option
            columnWidth: '.PortfolioGrid-Sizer'
        }
    });

    // filter functions
    var filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function( itemElem ) {
            var number = itemElem.querySelector('.number').textContent;
            return parseInt( number, 10 ) > 50;
        },
        // show if name ends with -ium
        ium: function( itemElem ) {
            var name = itemElem.querySelector('.name').textContent;
            return name.match( /ium$/ );
        }
    };

    // bind filter button click
    var filtersElem = document.querySelector('#portfolio-type');
    filtersElem.addEventListener( 'change', function( event ) {
        // only work with buttons
        if ( !matchesSelector( event.target, 'select' ) ) {
            return;
        }
        var filterValue = event.target.value;
        console.log(filterValue);
        // use matching filter function
        //filterValue = filterFns[ filterValue ] || filterValue;
        iso.arrange({ filter: filterValue });
    });
});

